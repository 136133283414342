$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1280px,
		lg: 1440px,
		xl: 1600px,
) !default;

$font: 'Grold', sans-serif;

$font-weight: 400;
$font-size: 16px;
$font-color: #000;

$bgr: #fff;

$link-font-color: #FF5745;
$link-font-color-hover: #333E63;

$wrapper-width: 1170px;
