$dark: #0d1c2e;

.logo {
	display: block;
	width: 200px;
	height: auto;
	margin: 0 auto 30px;

	@media (max-width: 500px) {
		width: 40vw;
		margin-bottom: .9em;
	}
}

.top {
	font-size: 140px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: #1152fc;

	@media (max-width: 500px) {
		font-size: 26vw;
	}
}

.bottom {
	font-size: 80px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	padding-top: 60px;
	color: #333e63;

	@media (max-width: 500px) {
		font-size: 13vw;
		padding-top: .8em;
	}
}

.rotor-group-heading {
	font-family: $font;
	color: #000;
	text-transform: capitalize;
}

.flipdown.flipdown__theme-dark .rotor, .flipdown.flipdown__theme-dark .rotor-top, .flipdown.flipdown__theme-dark .rotor-leaf-front,
.flipdown.flipdown__theme-dark .rotor-bottom, .flipdown.flipdown__theme-dark .rotor-leaf-rear {
	background-color: $dark;
}

.flipdown {
	height: auto;
	width: 300px;
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	grid-gap: 20px;

	.rotor {
		background-color: $dark!important;
	}

	.rotor-group {
		padding: 0;
		float: none;

		&-heading {
			&:before {
				color: $dark !important;
			}
		}

		&:before, &:after {
			display: none;
		}
	}
}

.content {
	padding: 32px;
	min-height: var(--app-height, 100vh);
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;
}

body {
	background-color: #f8f9fd;
}
